const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://ks9mpkg6b0.execute-api.us-east-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://4a1171d5li.execute-api.us-east-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.11.2',
    HOSTNAME: 'https://teams-service.staging.rossing.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.rossing.forwoodsafety.com',
    WEBSOCKET: 'wss://ywkefmoqw1.execute-api.us-east-1.amazonaws.com/staging'
  },
};

export default config;
